import React from 'react';
import CoffeeRain from './components/CoffeeRain';
import LottieBlock from './components/LottieBlock';
import ParallaxBlock from './components/ParallaxBlock';
import foreground1 from './for_web/home_for_web_2.png'
import foreground2 from './for_web/map_for_web.png'
import foreground3 from './for_web/cafe_for_web.png'
import foreground4 from './for_web/specials_for_web.png'
import HeaderBlock from './components/HeaderBlock';
import CafeFormBlock from './components/CafeFormBlock';
import CollidingBlock from './components/CollidingTap'
import './Block.css';
// import animation1 from './lotties/lottie_walkingcoffee.json' // walking coffee
import animation1 from './lotties/lottie_bell.json'
import animation3 from './lotties/connectcafe.json'
import animation4 from './lotties/connect.json'
import animation5 from './lotties/coffee_loading.json'
import animation_go_green from './lotties/gogreen.json'
import IntroVideo from './components/IntroVideo';
import videoSource from './lotties/BeanBonusIntro3.mp4'
import DownloadAppButton from './components/DownloadAppButton';
import parallaxbackground from './coffeetexture.png'
import FloatingButton from './components/FloatingButton'
// testing embedded video
import VideoEmbed from './components/VideoEmbed'

function LandingPage() {

    return (
        <div className="body">
            <CoffeeRain />
            <HeaderBlock />
            <CollidingBlock />
            {/* <VideoEmbed /> */}
            {/* <IntroVideo videoSource={videoSource}/> */}
            <FloatingButton />
            <LottieBlock 
                animationData={"none"} 
                header={"That Free Sip Feeling"}
                description={"Collect combined loyalty rewards from all your favourite coffee shops."}
                subdescription={["Earn free coffee by collecting stamps!", "Know exactly when your cafes have discounted day-olds!", "Access exclusive food and drink specials!"]}
                slide="left" />
            <DownloadAppButton />
            <ParallaxBlock backgroundImage={parallaxbackground} foregroundImageSrc={foreground1} textContent={"Digital\nStamp\nCard"} number={'one'} /> 
            <LottieBlock 
                animationData={animation1} 
                header={"Save Hundreds of Dollars"}
                description={"none"}
                subdescription={["Stay notified when dayolds hit the shelves.", "Donuts for 50-cents, muffins for a dollar, save big on baked goods.", "Be the first to know and never miss out."]}
                slide="left" />
            <ParallaxBlock backgroundImage={parallaxbackground} foregroundImageSrc={foreground2} textContent={"Explore\nNew\nGems"} number={'two'} /> 
            <LottieBlock 
                animationData={animation3} 
                header={"Support Your Community"} 
                description={"none"}
                subdescription={["Help small businesses thrive by choosing local.", "Support artisanal craftsmanship.", "Keep your community vibrant."]}
                slide="right" />
            <ParallaxBlock backgroundImage={parallaxbackground} foregroundImageSrc={foreground3} textContent={"Fresh\nDay\nOlds"} number={'three'} />       
            <LottieBlock 
                animationData={animation_go_green} 
                header={"Go Green!"} 
                description={"none"}
                subdescription={["Get rid of those pesky punch cards.", "Help reduce waste from extra baked goods.", "Become eco-friendly with BeanBonus."]}
                slide="left" />
            <ParallaxBlock backgroundImage={parallaxbackground} foregroundImageSrc={foreground4} textContent={"Special\nOffers"} number={'four'} /> 
            <LottieBlock 
                animationData={"none"} 
                header="What Are You Waiting For?" 
                description={"none"}
                subdescription={["Find us on the app store!", "Contact Us", "Phone: 416-500-5506", "Email: info@beanbonus.ca"]}
                slide="right" />
            <DownloadAppButton />
            <CafeFormBlock />
        </div>
    );
}

export default LandingPage;