import React, { useEffect, useRef } from 'react';
import '../../src/Block.css';

function ParallaxBlock({ backgroundImage, foregroundImageSrc, textContent, number }) {
  const blockRef = useRef(null);
  const foregroundImageRef = useRef(null);
  const textRef = useRef(null);

  const handleScroll = () => {
    if (!blockRef.current) return;

    const blockTop = blockRef.current.getBoundingClientRect().top;
    const windowHeight = window.innerHeight;
    
    // Check if the block is within the viewport
    if (blockTop < windowHeight && blockTop > -windowHeight) {
      const scrollY = window.pageYOffset;
      const imageSpeed = 0.3; // Adjust as needed

      if (foregroundImageRef.current) {
        const translateY = (blockTop - windowHeight) * imageSpeed;
        foregroundImageRef.current.style.transform = `translateY(${translateY}px)`;
      }

      if (textRef.current) {
        const translateY = (blockTop - windowHeight) * imageSpeed;
        textRef.current.style.transform = `translateY(${translateY}px)`;
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const parallaxStyle = {
    backgroundImage: `url(${backgroundImage})`, 
    // backgroundSize: 'cover',
    maxWidth: '100%',
    backgroundPosition: 'center',
    backgroundAttachment: 'scroll', 
    position: 'relative',
    height: '60vh',
    overflow: 'hidden',
  };

  return (
    <div ref={blockRef} className="parallax-block" style={parallaxStyle}>
      <img ref={foregroundImageRef} src={foregroundImageSrc} alt="Foreground" className={`foreground-image-${number}`} />
      <div ref={textRef} className={`parallax-text-${number}`}>
        {textContent}
      </div>
    </div>
  );
}


export default ParallaxBlock;
