import { initializeApp } from 'firebase/app';


const firebaseConfig = {
    apiKey: "AIzaSyDRWCNx7XYWKH2zO_gyy3BEC2YRxxPFWzg",
    authDomain: "beanbonusweb.firebaseapp.com",
    projectId: "beanbonusweb",
    storageBucket: "beanbonusweb.appspot.com",
    messagingSenderId: "279388401560",
    appId: "1:279388401560:web:8b8c8bdc8515eba02a05ed",
    measurementId: "G-7SVJQSZDVB"
}

// Initialize Firebase
export const app = initializeApp(firebaseConfig)
