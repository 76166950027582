// CoffeeRain.js
import React, { useEffect, useState } from 'react';
import CoffeeBean from './CoffeeBean';

const CoffeeRain = () => {
  const [beans, setBeans] = useState([]);

  useEffect(() => {
    const addBean = () => {
      const spinDirection = Math.random() < 0.5 ? '-' : ''; // Randomly choose clockwise or counterclockwise
      const rotations = Math.floor(Math.random() * 360 + 360); // Random rotation between 360 and 720 degrees
      const duration = Math.random() * 6 + 4; // Duration between 4 and 10 seconds

      const beanStyle = {
        left: `${Math.random() * 100}vw`,
        animation: `fall-and-spin ${duration}s linear infinite`,
        '--rotation': `${spinDirection}${rotations}deg` // CSS variable for dynamic rotation
      };
      const newBean = <CoffeeBean key={new Date().getTime()} style={beanStyle} />;
      setBeans(beans => [...beans, newBean]);

      // Schedule removal of this bean after its animation ends
      setTimeout(() => {
        setBeans(beans => beans.filter(bean => bean !== newBean));
      }, duration * 1000);
    };

    // Add beans at a consistent interval
    const interval = setInterval(addBean, 2000);

    return () => clearInterval(interval);
  }, []);

  return <div>{beans}</div>;
};

export default CoffeeRain;
