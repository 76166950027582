import React, { useState, useEffect } from 'react';
import '../Block.css'; // Make sure to create this CSS file

const FloatingButton = () => {
    const scrollToBottom = () => {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: 'smooth',
      });
    };
  
    const [showButton, setShowButton] = useState(false);
  
    useEffect(() => {
      const handleScroll = () => {
        if (window.scrollY > window.innerHeight) {
          setShowButton(true);
        } else {
          setShowButton(false);
        }
      };
  
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  
    return (
      showButton && (
        <button className={`floating-button ${showButton ? 'visible' : 'hidden'}`} onClick={scrollToBottom}>
          <span className="launch-day">Launch Day Deal</span>
          <span className="free-for-life">first cafe in your city</span>
          <span className="free-for-life">first year free</span>
        </button>
      )
    );
  };
  
  export default FloatingButton;