import React, { useState } from 'react';
import '../../src/Block.css';
import { app } from '../firebaseConfig'
import { httpsCallable, getFunctions } from 'firebase/functions' 

const CafeFormBlock = () => {
    const functions = getFunctions(app)
    const [formData, setFormData] = useState({
        name: '',
        cafeName: '',
        address: '',
        email: '',
    })
    const [isSubmitted, setIsSubmitted] = useState(false)

    // Handle input change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitted(true)

        // Validation check for empty fields
        if (!formData.name || !formData.cafeName || !formData.address || !formData.email) {
            setIsSubmitted(false)
            alert('Please fill in all the fields.');
            return; // Stop the function if any field is empty
        }

        const sendEmail = httpsCallable(functions, 'sendEmail');
        try {
            console.log('Attempting to send email with the following data:', formData);
            const result = await sendEmail({
                name: formData.name,
                cafeName: formData.cafeName,
                address: formData.address,
                email: formData.email,
            });
            if (result.data.success) {
                console.log('Email sent successfully:', result);
                setIsSubmitted(false)
                alert('Thank you for showing interest, we will be in touch shortly!');
            } else {
                console.log('Email failed to send:', result)
                setIsSubmitted(false)
                alert('Sorry but something went wrong, please try again or contact us by sending an email or giving us a ring.')
            }
            setFormData({
                name: '',
                cafeName: '',
                address: '',
                email: '',
            });
        } catch (error) {
            setIsSubmitted(false)
            console.error('Error sending email:', error);
            alert('There was a problem sending your message. Please try again.');
        }
    }

    return (
        <div className="form-container" style={{ flexDirection: 'column' }}>
            <form onSubmit={handleSubmit} className="form">
                <div>
                    <h2 className="form-header">Are You A Cafe Owner?</h2>
                    <p className="form-text">{"Fill out the form to receive a free demo today and start driving more customers in the door tomorrow!"}</p>
                </div>
                <input
                    type="text"
                    name="name"
                    placeholder="Your Name"
                    value={formData.name}
                    onChange={handleChange}
                    className="form-input"
                />
                <input
                    type="text"
                    name="cafeName"
                    placeholder="Your Cafe's Name"
                    value={formData.cafeName}
                    onChange={handleChange}
                    className="form-input"
                />
                <input
                    type="text"
                    name="address"
                    placeholder="Cafe's Address (e.g. 123 Main St, Vancouver)"
                    value={formData.address}
                    onChange={handleChange}
                    className="form-input"
                />
                <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleChange}
                    className="form-input"
                />
                <button type="submit" className="form-submit">Submit</button>
            </form>
        </div>
    );
};

export default CafeFormBlock;
