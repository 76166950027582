import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { Player } from '@lottiefiles/react-lottie-player';
import '../../src/Block.css'; 

function LottieBlock({ animationData, header, description, subdescription = [], slide }) {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  const [visibleIndex, setVisibleIndex] = useState(0);

  useEffect(() => {
    if (inView) {
      const interval = setInterval(() => {
        if (visibleIndex < subdescription.length) {
          setVisibleIndex(prevIndex => prevIndex + 1);
        } else {
          clearInterval(interval);
        }
      }, 1000); // Adjust the delay between each element's appearance

      return () => clearInterval(interval);
    }
  }, [inView, subdescription.length]);

  const slideClass = slide === 'left' ? 'block-left' : 'block-right';

  return (
    <div ref={ref} className={`${slideClass} ${inView ? 'visible' : ''}`}>
      {animationData === "none" ? (
        <div style={{ marginTop: '10vh' }}></div>
      ) : (
        <div className='lottie-wrapper'>
          <Player src={animationData} loop autoplay className="lottie-container" />
        </div>
      )}
      <div className="block-content">
        <h2 className="block-header">{header}</h2>
        <div className="block-textbox">
          {description === "none" ? (
            <></>
          ) : (
            <p className="block-text">{description}</p>
          )}
          {subdescription.map((element, index) => (
            <p 
              key={index} 
              className={`block-subtext ${index < visibleIndex ? 'visible' : ''}`}
            >
              {element}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
}

export default LottieBlock;