import React from 'react';
import { useInView } from 'react-intersection-observer';
import '../../src/Block.css';
import phonePNG from '../../src/for_web/beanbonus_nfc_tap_showcase.png'
import boxPNG from '../../src/for_web/Render 1.png'

function CollidingBlock() {
  const [ref, inView] = useInView({
    triggerOnce: false,
    threshold: 0.3,
  });

  return (
    <div ref={ref} className={`colliding-block ${inView ? 'visible' : ''}`}>
      <div className='colliding-wrapper'>
        {/* Phone screenshot */}
        <img src={phonePNG} alt="Phone Screenshot" />
        {/* Position block */}
        <img src={boxPNG} alt="Position Block" />
      </div>
    </div>
  );
}

export default CollidingBlock;