// CoffeeBean.js
import React from 'react';
import './CoffeeBean.css'; // Ensure this path is correct
import beanimg from '../iconBackgroundless.png'

const CoffeeBean = ({ style }) => (
  <img src={beanimg} style={style} className="coffee-bean" alt="Coffee Bean" onError={"none"} />
);

export default CoffeeBean;
